<template>
  <div class="flex justify-center items-center w-full">
    <div
      class="w-full h-full md:w-8/12 sm:w-10/12 mb-1 rounded bg-opacity-90 md:shadow-lg max-w-lg flex flex-col items-center justify-between h-100"
      style="background-color: rgba(255, 251, 197, 0.38)"
    >
      <section
        class="pb-2 sm:py-10 sm:px-10 dark:text-white relative w-full z-20"
      >
        <div>
          <img
            class="mx-auto w-24 mb-2 mt-1"
            :src="logo"
            alt="logo"
            loading="eager"
          />
        </div>
        <div class="px-2" v-if="isWeekend">
          <div class="party-content" v-if="!responsePayment">
            <PaymentFailedComponent
              message="Cher client, l'abonnement à votre Cameroon Tribune n'a pas abouti. Veuillez vérifier votre solde ou choisssez un autre forfait"
              v-if="paymentFailedStatus.includes(paymentStatus)"
            />
            <div
              v-else
              class="text-center sm:px-4 px-2 border-t border-b border-green my-4 text-sm"
            >
              {{ title }}
            </div>
            <div
              class="flex flex-wrap items-center justify-center gap-6 py-3 sm:py-8 gap-x-2 sm:gap-y-10 sm:gap-x-5"
              @contextmenu="
                (e) => {
                  e.preventDefault();
                  return false;
                }
              "
            >
              <div
                v-for="newspaper in newspapers"
                @click="handleOpenModal(newspaper.img)"
                class="newspaper-img relative shadow-md flex justify-center cursor-pointer"
              >
                <span
                  class="absolute left-1 p-1 text-xs transform -rotate-6 bg-green text-white rounded-md -top-3"
                  >{{ newspaper.date }}</span
                >
                <img :src="newspaper.img" :alt="newspaper.title" />
                <button
                  class="absolute bottom-4 rounded-full px-2 bg-black bg-opacity-60 text-xs sm:text-sm text-white border border-white mx-auto"
                >
                  Consulter
                </button>
              </div>
            </div>
            <LoaderComponent v-if="isLoading" />
            <div class="mb-8" v-else>
              <fieldset class="w-full border-t border-green">
                <legend class="px-2 text-base mx-auto">
                  {{ paymentMessage }}
                </legend>
                <p
                  class="p-4 bg-red-500 text-white rounded my-4 text-sm"
                  v-if="this.error"
                >
                  {{ this.error }}
                </p>
                <div
                  class="px-2 sm:px-8 text-center relative mb-4 mt-2 grid gap-3"
                >
                  <ListBoxComponent
                    :subscriptions="this.subscriptions"
                    v-model:value="subscriptionSelected"
                    :value="subscriptionSelected"
                    placeholder="S'abonner"
                  />
                  <InputNumberComponent
                    :value="phoneNumber"
                    v-model:value="phoneNumber"
                    v-model:error="error"
                  />
                  <ButtonPaymentComponent @click="pay()" />
                </div>
              </fieldset>
            </div>
          </div>
          <div v-else class="mt-10 mb-10">
            <div class="h-64 flex items-center">
              <p
                class="rounded-md text-white p-4 w-full text-center"
                :class="
                  (paymentInitSuccess && !this.error) ||
                  paymentStatus === 'SUCCESS'
                    ? 'bg-green '
                    : 'bg-red-500'
                "
              >
                {{ paymentResponseMessage }}
              </p>
            </div>

            <PaymentInitSuccessComponent v-if="paymentInitSuccess" />
          </div>
        </div>
        <div v-else>
          <div class="text-center py-10 rounded-sm" v-if="error">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="2em"
              viewBox="0 0 512 512"
              class="mx-auto mb-4"
            >
              <path
                d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
              />
            </svg>
            Cette page est disponible uniquement le weekend, pour acheter le
            journal du jour cliquez
            <a href="/" class="text-green font-extrabold">ICI</a>
          </div>
          <div v-else class="text-center py-10 rounded-sm bg-white">
            <LoaderComponent />
          </div>
        </div>
      </section>

      <p class="text-center mb-4">
        <a
          href="https://nexah.net"
          class="text-black text-opacity-80 underline dark:text-white"
          style="font-size: 11px"
          >Fourni par NEXAH</a
        >
      </p>

      <transition
        enterActiveClass="transition duration-500 ease-in"
        enterFromClass="opacity-0"
        enterToClass="opacity-100"
        leave-active-class="transition duration-500 ease-in"
        leave-to-class="opacity-0"
      >
        <div
          v-if="newspaperSelected"
          className="z-30 fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        >
          <div class="relative">
            <button
              class="bg-red-500 rounded-full p-1 absolute -top-5 right-1 text-white z-50"
              @click="handleCloseModal"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="w-6 h-6"
              >
                <path
                  d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
                />
              </svg>
            </button>
            <img
              @contextmenu="
                (e) => {
                  e.preventDefault();
                  return false;
                }
              "
              :src="newspaperSelected"
              alt="Cameroo tribune"
              class="w-11/12 max-w-md mx-auto"
            />
          </div>
        </div>
      </transition>
    </div>
    <ConfettiComponent v-if="promotion" />
    <ModalComponent v-model:modalIsOpen="modalIsOpen" :img="promotion?.img" />
  </div>
</template>

<script>
import LoaderComponent from "@/components/LoaderComponent";
import ListBoxComponent from "@/components/ListBoxComponent";
import PaymentInitSuccessComponent from "@/components/PaymentInitSuccessComponent";
import PaymentFailedComponent from "@/components/PaymentFailedComponent";
import PayIconComponent from "@/components/Icons/PayIconComponent";
import ButtonPaymentComponent from "@/components/ButtonPaymentComponent";
import InputNumberComponent from "@/components/InputNumberComponent";
import ModalComponent from "@/components/ModalComponent";
import ConfettiComponent from "@/components/ConfettiComponent";
import axios from "axios";
import logoImg from "@/assets/logo.png";
import omImg from "@/assets/om.png";
import momoImg from "@/assets/momo.jpeg";

export default {
  name: "WeekendSubscription",
  inject: ["apiUrl"],
  data() {
    return {
      isLoading: false,
      logo: logoImg,
      om: omImg,
      momo: momoImg,
      title:
        "Découvrez les faits marquants de l’actualité au courant de cette semaine",
      paymentResponseMessage: "",
      paymentMessage: "Abonnez-vous ICI",
      responsePayment: false,
      paymentInitSuccess: false,
      interval: null,
      phoneNumber: null,
      error: null,
      subscriptions: null,
      subscriptionSelected: null,
      isWeekend: false,
      mode: "Web",
      paymentStatus: "",
      paymentFailedStatus: ["EXPIRED", "FAILED", "CANCELLED"],
      newspapers: [],
      newspaperSelected: null,
      modalIsOpen: false,
      promotion: null,
      link: [
        "fbk-all",
        "fbk-nd",
        "fbk-ces",
        "fbk-adc",
        "fbk-coc",
        "fbk-csc",
        "fbk-cc",
        "fbk-vc",
        "fbk-oe",
        "fbk-oec1",
        "fbk-oeec",
        "fbk-lsat",
        "fbk-ac",
        "fbk-oec2",
        "fbk-adcmr",
        "wa",
        "fbk",
      ],
      deviceId: "",
    };
  },

  mounted() {
    const fpPromise = FingerprintJS.load();

    (async () => {
      // Get the visitor identifier when you need it.
      const deviceSecretGotten = await getDeviceSecret();
      this.deviceId = deviceSecretGotten;
      console.log("Visitor ID:  ===========", deviceSecretGotten);
    })();
    axios
      .get(`${this.apiUrl}/newspapers-week/1`)
      .then((response) => {
        const datasNewspapers = response.data.data.newspapers;
        const oldThis = this;
        datasNewspapers.forEach((newspaper, index) => {
          const img = new Image();
          img.src = newspaper.img;
          img.addEventListener("load", (e) => {
            if (index === 0) {
              oldThis.isWeekend = true;
              oldThis.promotion = response.data.data.promotion;
              oldThis.modalIsOpen = null !== response.data.data.promotion;
            }
          });
          img.addEventListener("error", (e) => {
            oldThis.isWeekend = true;
            oldThis.promotion = response.data.data.promotion;
            oldThis.modalIsOpen = null !== response.data.data.promotion;
          });
        });
        this.newspapers = datasNewspapers;
        this.subscriptions = response.data.data.subscriptions;
        if (this.$route.params.phone) {
          try {
            if (this.link.includes(this.$route.params.phone.toLowerCase())) {
              this.mode = this.$route.params.phone.toLowerCase();
            } else {
              this.phoneNumber = atob(this.$route.params.phone);
              if (this.checkNumber()) {
                this.mode = "sms";
              } else {
                this.phoneNumber = "";
              }
            }
          } catch (error) {
            console.log(error);
          }
          this.subscriptionSelected = this.subscriptions.find(
            (subscription) => subscription.is_default === 1
          );
        } else {
          this.subscriptionSelected =
            response.data.data.subscriptions[
              response.data.data.subscriptions.length - 1
            ];
        }
      })
      .catch((error) => {
        console.log(error);
        this.error = true;
      });
  },
  methods: {
    async pay() {
      if (this.checkNumber() && this.subscriptionSelected) {
        this.isLoading = true;
        this.title = "L'abonnement à votre Cameroon Tribune est en cours...";
        this.paymentStatus = null;
        await axios
          .post(`${this.apiUrl}/subscription-weekend`, {
            phoneNumber: `${this.phoneNumber}`,
            subscriptionId: this.subscriptionSelected.id,
            canal: this.$route.params.mode
              ? this.$route.params.mode
              : this.mode,
            editionId: 1,
            deviceId: this.deviceId,
          })
          .then((response) => {
            this.paymentResponseMessage =
              "Paiement initié avec succès. Veuillez patienter, vous allez recevoir une notification pour valider votre paiement";
            this.checkPayment(response.data.payment_ref);
            this.paymentInitSuccess = true;
            this.responsePayment = true;
          })
          .catch((error) => {
            this.title =
              "Vous êtes sur le point de vous réabonner à votre quotidien Cameroon Tribune";
            this.error =
              error.data?.message ??
              "Cher client, l'abonnement n'a pas abouti. Veuillez essayer plus tard. Infoline: 699999095";
          });
        this.isLoading = false;
      } else {
        if (!this.subscriptionSelected) {
          this.error = "Sélectionner votre forfait";
        }
      }
    },
    checkNumber() {
      if (this.phoneNumber) {
        const regex = new RegExp(/^(237)?6((8|7|9)\d{7}|5([0-4]|[5-9])\d{6})$/);
        if (regex.test(this.phoneNumber)) {
          this.error = false;
          return true;
        }
        this.error =
          "Le numéro saisi ne correspond pas à un numéro MTN ou Orange";
      } else {
        this.error = "Vous devez renseigner votre numéro de téléphone";
      }
      return false;
    },
    checkPayment(payment_ref) {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        axios
          .post(`${this.apiUrl}/pay-status/` + payment_ref, {
            code: this.$route.params.code,
          })
          .then((response) => {
            this.paymentStatus = response.data.status;
            if (response.data.status === "SUCCESS") {
              this.paymentResponseMessage =
                "Cher client, l'abonnement au Cameroon Tribune a été éffectué avec succes, vous allez recevoir un message de confirmation avec tous les détails. Infoline: 699999095";
              clearInterval(this.interval);
              this.paymentInitSuccess = false;
              this.paymentStatus = "SUCCESS";
              this.error = false;
            } else if (
              this.paymentFailedStatus.includes(response.data.status)
            ) {
              clearInterval(this.interval);
              this.responsePayment = false;
              this.title = "";
              const index = this.subscriptions.findIndex(
                (subscription) =>
                  subscription.id === this.subscriptionSelected.id
              );
              if (index < this.subscriptions.length - 1) {
                this.subscriptionSelected = this.subscriptions[index + 1];
              }
              this.paymentMessage = "Choisir un autre forfait";
            }
          });
      }, 5000);
    },
    handleOpenModal(img) {
      this.newspaperSelected = img;
    },
    handleCloseModal() {
      this.newspaperSelected = null;
    },
  },
  components: {
    LoaderComponent,
    ListBoxComponent,
    PayIconComponent,
    PaymentInitSuccessComponent,
    ButtonPaymentComponent,
    PaymentFailedComponent,
    InputNumberComponent,
    ModalComponent,
    ConfettiComponent,
  },
};
</script>
<style scoped>
.shadow-dark {
  box-shadow: -1px 1px 5px 2px #8c8d8d;
}

.newspaper-img {
  flex-basis: 125px;
}

@media (max-width: 400px) {
  .newspaper-img {
    flex-basis: 110px;
  }

  .text-xs {
    font-size: 0.65rem;
  }
}
</style>
